<template>
    <div id="consumption-histogram-chart-widget-component">
        <widget-wrapper-component :title="$t('histogram_consumption.histogram')">
            <template v-slot:default>
                <template v-if="metricsToDisplay.length > 0">
                    <v-tabs
                        v-model="current_tab"
                        :color="$store.getters['display_option/display_option'].primary"
                        align-tabs="start"
                        class="mb-4"
                        density="comfortable"
                        @change="handleNewCurrentTab"
                    >
                        <v-tab v-for="(metric, index) in metricsToDisplay" :key="index" :value="index">
                            <template v-slot:default>
                                <span :class="current_tab === index ? '!font-bold' : 'text-skin-light'">
                                    {{ metric.label }} ({{ metric.unit }})
                                </span>
                            </template>
                        </v-tab>
                    </v-tabs>

                    <date-range-picker-component
                        ref="dateRangePicker"
                        :date_range="date_range"
                        :loading="fetching_dtd"
                        class="w-100"
                        v-on:update="updateRangeDate"
                    >
                        <template v-slot:action-extra>
                            <v-radio-group v-model="interval" row>
                                <div class="radio-wrapper">
                                    <v-radio :label="$t('date_alert_form_component.days')" value="day"></v-radio>
                                    <v-radio :label="$t('date_alert_form_component.weeks')" value="week"></v-radio>
                                    <v-radio :label="$t('date_alert_form_component.months')" value="month"></v-radio>
                                    <v-radio :label="$t('date_alert_form_component.years')" value="year"></v-radio>
                                </div>
                            </v-radio-group>
                        </template>
                    </date-range-picker-component>

                    <br />

                    <v-radio-group v-model="metrics" row class="justify-center" @change="changeMetric">
                        <div class="radio-wrapper">
                            <v-radio :color="$store.getters['display_option/display_option'].primary" label="Auto" value="Consumption">
                                <template v-slot:label>
                                    <div>
                                        {{ $t('histogram_consumption.consumption') }}
                                        <v-tooltip top>
                                            {{ $t('histogram_consumption.consumption_volume_info') }}

                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="info" size="small" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                                            </template>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-radio>

                            <v-radio :color="$store.getters['display_option/display_option'].primary" label="Auto" value="Fill">
                                <template v-slot:label>
                                    <div>
                                        {{ $t('histogram_consumption.fill') }}
                                        <v-tooltip top>
                                            {{ $t('histogram_consumption.fill_volume_info') }}

                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="info" size="small" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                                            </template>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-radio>
                        </div>
                    </v-radio-group>
                    <br />
                    <template v-if="!fetching_dtd">
                        <highcharts :options="options" />
                    </template>
                </template>
                <template v-else>
                    {{ $t('consumption_report_widget_component.no_variation_metrics_detected') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import DateRangePickerComponent from '@/components/Global/DateRangePickerComponent.vue'
import { nextTick } from 'vue'
import moduleRepository from '@/repositories/ModuleRepository'

export default {
    name: 'ConsumptionHistogramChartWidgetComponent',
    components: { DateRangePickerComponent, WidgetWrapperComponent },
    props: {
        asset_module: {
            type: Object,
            required: true,
        },
        device: {
            type: Object,
            required: true,
        },
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            options: {
                chart: {
                    type: 'column',
                    stacked: true,
                    zooming: {
                        mouseWheel: {
                            enabled: true,
                        },
                    },
                    animation: {
                        duration: 500, // Animation de chargement
                    },
                },
                exporting: {
                    accessibility: {
                        enabled: true,
                    },
                },
                title: {
                    text: '',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#333', // Couleur de titre
                    },
                },
                legend: {
                    enabled: false,
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Montserrat',
                        },
                        step: 0,
                        y: 20,
                    },
                    tickPosition: 'outside',
                    tickWidth: 0.5, // Largeur de la marque (tick)
                    tickLength: 8, // Longueur de la marque (tick)
                    lineWidth: 0, //
                    tickInterval: 0, // Afficher un tick toutes les x catégories
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    labels: {
                        formatter: (options) => {
                            return options.value.toString() +  this.metric_symbol
                        },
                    },
                    min: 0,
                },
                navigator: {
                    enabled: true,
                    xAxis: {
                        type: 'datetime',
                    }

                },
                plotOptions: {
                    column: {
                        pointPadding: 0.4, // Réduit l'espacement entre les barres pour mieux voir les arrondis
                        borderWidth: 0, // Épaisseur de la bordure
                        groupPadding: 0.1, // Espace entre les groupes de barres
                        endingShape: 'rounded',
                        borderRadius: 25, // Augmenter l'arrondi des coins des barres
                        color: '#1BA1DC', // Couleur par défaut des barres
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                    },
                },
                series: [
                    {
                        name: this.$t('histogram_consumption.consumption') + ' %',
                        data: [],
                        color: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1,
                            },
                            stops: [
                                [0, '#1BA1DC'], // Début du dégradé (en haut)
                                [0.5, '#68CBF7'], // Milieu du dégradé
                                [1, '#AAE5FF'], // Fin du dégradé (en bas)
                            ],
                        },
                        dataLabels: {
                            enabled: false,
                            color: '#fff',
                            style: {
                                fontSize: '12px',
                                fontWeight: 'bold',
                            },
                            verticalAlign: 'bottom',
                            y: -10,
                        },
                    },
                ],
                tooltip: {
                    shared: true,
                    crosshairs: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    style: {
                        color: '#fff',
                    },
                },
            },
            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(1, 'month').toDate(),
                endDate: this.moment().toDate(),
            },

            fetching_dtd: true,
            interval: 'month',
            metrics: 'Consumption',
            metric_symbol: ' %',
            current_tab: 0,
            refresh_for_new_tab: false,
            all_consumptions: [],
            consumptions: [],
        }
    },
    computed: {
        metricsToDisplay() {
            return this.asset_module.default_metrics_configuration.metrics.filter(
                (metric) => JSON.parse(metric.parameters).displayed_in_widget_histogram_consumption
            )
        },

        idMetric() {
            return this.asset_module.default_metrics_configuration.metrics.findIndex(
                (metric) => metric.parameters === this.metricsToDisplay[this.current_tab].parameters
            )
        },
    },
    methods: {
        updateRangeDate(date_range) {
            this.date_range = date_range
            this.fetchHistogramConsumption()
        },

        changeMetric() {
            this.fetchHistogramConsumption()
            const metric = this.metricsToDisplay[this.idMetric]
            this.metric_symbol = ` ${metric.unit}`
            if (this.metrics === 'Consumption') {
                nextTick(() => {
                    this.options.series[0].name = this.$t('histogram_consumption.consumption') + this.metric_symbol
                    this.options.series[0].color = {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, '#1BA1DC'],
                            [0.5, '#68CBF7'],
                            [1, '#AAE5FF'],
                        ],
                    }
                })
            } else if (this.metrics === 'Fill') {
                nextTick(() => {
                    this.options.series[0].name = this.$t('histogram_consumption.fill') + this.metric_symbol
                    this.options.series[0].color = {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, '#EFAC4B'],
                            [0.5, '#F2C787'],
                            [1, '#F6E1C4'],
                        ],
                    }
                })
            }
        },

        intervalChange() {
            if (this.interval === 'day') {
                this.options.xAxis.labels.y = 30
                this.options.xAxis.categories = this.consumptions.map((data) => {
                    return this.moment(data.dateStart).format('DD/MM/YY')
                })
                this.options.xAxis.tickInterval = this.consumptions.length < 50 ? 0 : 10
            } else if (this.interval === 'week') {
                this.options.value.xAxis.tickInterval = 0
                this.options.value.xAxis.labels.y = 20
                this.options.value.xAxis.categories = this.consumptions.map((data) => {
                    return this.moment(data.dateStart).format('DD/MM/YY')
                })
            }else if (this.interval === 'month') {
                this.options.xAxis.tickInterval = 0
                this.options.xAxis.labels.y = 20
                this.options.xAxis.categories = this.consumptions.map((data) => {
                    return this.moment(data.dateStart).format('MMM')
                })
            } else if (this.interval === 'year') {
                this.options.xAxis.tickInterval = 0
                this.options.xAxis.labels.y = 20
                this.options.xAxis.categories = this.consumptions.map((data) => {
                    return this.moment(data.dateStart).format('YYYY')
                })
            }
            this.options.navigator = {
                ...this.options.navigator,
                xAxis: {
                    categories: this.options.xAxis.categories,
                },
            };
        },

        fetchHistogramConsumption() {
            this.fetching_dtd = true
            moduleRepository
                .postConsumptionByDates(this.asset_module.id_module, this.device.id_device, {
                    date_begin: this.date_range.startDate,
                    date_end: this.date_range.endDate,
                    interval: this.interval,
                })
                .then((success) => {
                    this.all_consumptions = success.data.data
                    this.consumptions = success.data.data[this.idMetric]
                    let conso
                    if (this.metrics === 'Consumption') {
                        conso = this.consumptions.map((data) => {
                            return Math.round(data.consumption)
                        })
                    } else if (this.metrics === 'Fill') {
                        conso = this.consumptions.map((data) => {
                            return Math.round(data.positive)
                        })
                    }

                    nextTick(() => {
                        this.options.series[0].data = conso
                        this.intervalChange()
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_dtd = false
                })
        },

        handleNewCurrentTab() {
            this.consumptions = this.all_consumptions[this.idMetric]
            this.changeMetric()
            this.refresh_for_new_tab = true
            nextTick(() => {
                this.refresh_for_new_tab = false
            })
        },
    },
    created() {
        this.fetchHistogramConsumption()
    },
}
</script>
<style>
.radio-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
</style>
