export interface IAlert {
    id_alert: number
    id_device: number
    label: string
    space_category: string
    configuration: Array<any>
    configuration_appearance: IConfigurationAppearance
    notification_default_badge: boolean
    notification_default_email: boolean
    notification_default_sms: boolean
    notification_default_push: boolean
    trigger_frequency_default: number
    acquitted: boolean
    triggered: boolean
    is_show_in_charts: boolean
    user_alert_preference: IUserAlertPreference
}

interface IConfigurationAppearance {
    color_triggered: string
    color_untriggered: string
    icon_triggered: string
    icon_untriggered: string
}

interface IUserAlertPreference {
    id_subscription_alert_configuration: number | null
    notification_badge: boolean
    notification_email: boolean
    notification_push: boolean
    notification_sms: boolean
    trigger_frequency: number
}

export const EmptyAlert: IAlert = {
    acquitted: false,
    configuration: [
        {
            time_unites_selected: null,
            id_metric: null,
            limit: null,
            operator: null,
            type: null,
            variation_time: null,
            unit: null,
        },
    ],
    configuration_appearance: {
        color_triggered: '#C23F37',
        color_untriggered: '#61c232',
        icon_triggered: 'alert-outline',
        icon_untriggered: 'alert-outline',
    },
    id_alert: 0,
    id_device: 0,
    label: '',
    notification_default_badge: true,
    notification_default_email: false,
    notification_default_push: false,
    notification_default_sms: false,
    space_category: '',
    trigger_frequency_default: -1,
    triggered: false,
    is_show_in_charts: false,
    user_alert_preference: {
        id_subscription_alert_configuration: null,
        notification_badge: true,
        notification_email: false,
        notification_push: false,
        notification_sms: false,
        trigger_frequency: -1,
    },
}
