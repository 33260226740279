import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IDisplayOption } from '@/models/IDisplayOption'

const resource = 'api/v1/device_traited_data'

export default {
    getDtdTriggers(): Promise<AxiosResponse<IServerResponse<IDisplayOption>>> {
        return axios.get(`${resource}/triggers`)
    },
}
