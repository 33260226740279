import i18n from '@/plugins/i18n'

export default {
    methods: {
        displayToHumanNumberWithSpace(value) {
            if (typeof value === 'number') {
                return Number.isInteger(value)
                    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    : value
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }

            return value
        },

        displayToHumanDomicileAddress(domicile, message = 'Aucun domicile associé') {
            if (domicile) {
                return `${domicile.street_number ?? ''} ${domicile.address ?? ''}, ${domicile.postal_code ?? ''} ${domicile.city ?? ''}`
            }

            return message
        },

        displayToHumanOrientation(orientation) {
            let orientationLabel = this.$t('information_device_component.not_working')

            switch (parseInt(orientation)) {
                case 1:
                    orientationLabel = this.$t('information_device_component.vertical')
                    break
                case 2:
                    orientationLabel = this.$t('information_device_component.vertical_inverse')
                    break
                case 3:
                    orientationLabel = this.$t('information_device_component.horizontal')
                    break
            }

            return orientationLabel
        },

        displayToHumanAssetMetricFromCompleteDTd(device_traited_data, parameter) {
            const output = JSON.parse(parameter)['output']

            if (device_traited_data[output[0]][output[1]] && device_traited_data[output[0]][output[1]][output[2]] !== null) {
                return device_traited_data[output[0]][output[1]][output[2]]
            }

            return '-'
        },

        displayToHumanTrigger(item) {
            const triggers = this.$store.getters['general/dtd_triggers']
            const triggerIndex = Object.keys(triggers).find((key) => key === JSON.stringify(item))
            return triggers[triggerIndex]
        },

        displayToHumanError(erreur, heightLiquid) {
            switch (this.convertErrorToDisplay(erreur)) {
                case -1:
                    return this.$t('display_to_human_error.error_unreliable_measurement')
                case 0:
                    if (heightLiquid < -10) {
                        return this.$t('display_to_human_error.error_incorrectly_configured_container')
                    }
                    return '✓'
                case 1:
                    return this.$t('display_to_human_error.error_incorrectly_configured_container')
                case 2:
                    return this.$t('display_to_human_error.error_too_far')
                default:
                    return '/'
            }
        },

        convertErrorToDisplay(erreur) {
            if (erreur < 0) {
                return erreur
            }

            let erreur_binary = parseInt(erreur, 10).toString(2).padStart(4, '0').toString()

            if (erreur_binary.slice(2, 3) === '1') {
                return 1 //trop près
            } else if (erreur_binary.slice(1, 2) === '1') {
                return 2 //trop loin
            } else if (erreur_binary.slice(0, 1) === '1') {
                return 3 //trop echo
            } else if (erreur_binary.slice(3, 4) === '1') {
                return 4 //erreur accel
            }
            return 0
        },
    },
}
