var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100",attrs:{"id":"asset-generic-card-minimalist-component"}},[(_vm.is_mobile)?[_c('v-card',{class:`${_vm.is_selected ? 'minimalist-asset-card-selected' : 'minimalist-asset-card'} h-100 text-center pointer`,attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-1"},[_c('div',{staticClass:"text-center font-12 text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.asset.name)+" ")]),_c('div',{staticClass:"font-weight-bold font-10 font-color-medium mb-1"},[_vm._v(" "+_vm._s(_vm.asset.code_asset)+" ")]),_vm._l((_vm.total_models),function(n,index_model){return [_c('div',{key:n,staticClass:"mb-2"},[(_vm.configuration[index_model]['is_required'])?[_c('v-progress-linear',{attrs:{"color":_vm.is_selected
                                        ? _vm.$store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                                              _vm.asset_module.id_module,
                                              _vm.asset.id_asset,
                                              index_model
                                          ).color
                                        : '#9399a3',"value":_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index_model, 'percent'),"height":"4","rounded":""}})]:[_c('v-progress-linear',{attrs:{"color":"#9399a3","height":"4","rounded":""}})]],2)]}),_c('div',{staticClass:"font-10 font-color-light line-height-12"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-map-marker-outline")]),_vm._v(" "+_vm._s(_vm.asset.domicile.address)),_c('br'),_vm._v(" "+_vm._s(_vm.asset.domicile.postal_code)+" "+_vm._s(_vm.asset.domicile.city)+" ")],1)],2)],1)]:[_c('div',{staticClass:"position-relative"},[_c('v-card',{class:`${_vm.is_selected ? 'minimalist-asset-card-selected' : 'minimalist-asset-card'} desktop-asset-card h-100 pointer`,attrs:{"data-cy":"minimalist-asset-card","elevation":"0"}},[_c('v-card-text',{staticClass:"h-100 position-relative"},[_c('div',{staticClass:"font-weight-bold font-18 text-overflow-ellipsis font-color-default"},[_vm._v(" "+_vm._s(_vm.asset.name)+" ")]),_c('div',{staticClass:"font-weight-bold font-12 font-color-medium"},[_vm._v(" "+_vm._s(_vm.asset.code_asset)+" ")]),_c('v-tooltip',{attrs:{"open-delay":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"font-12 ml-n1 mb-2 d-flex line-height-16 min-height-32"},'div',attrs,false),on),[_c('v-icon',{staticClass:"font-color-medium align-stretch",attrs:{"dense":""}},[_vm._v(" mdi-map-marker-outline ")]),_c('span',{staticClass:"font-color-medium text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.getFormattedAddress(_vm.asset.domicile))+" ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.getFormattedAddress(_vm.asset.domicile))+" ")]),(_vm.asset.is_subscription_device_active)?[_c('div',{class:`
                                ${_vm.total_models === 1 ? 'd-flex justify-space-between align-center gap-2 ' : ''}  overflow-hidden
                            `},[(_vm.total_models === 1)?[(_vm.configuration[0]['is_required'])?[_c('display-asset-content-level-component',{attrs:{"asset":_vm.asset,"asset_module":_vm.asset_module,"colorless":!_vm.is_selected,"index_model":0,"scale":0.4}})]:[_c('div',{staticClass:"d-flex grow justify-center"},[_c('v-icon',{staticClass:"font-color-light"},[_vm._v(" mdi-cancel")])],1)]]:_c('div',{staticClass:"d-flex flex-column gap-2 mb-4"},[_vm._l((_vm.total_models),function(n,index_model){return [_c('div',{key:n,staticClass:"flex-column gap-2 position-relative"},[(_vm.configuration[index_model]['is_required'])?[_c('v-progress-linear',{attrs:{"color":_vm.is_selected
                                                        ? _vm.$store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                                                              _vm.asset_module.id_module,
                                                              _vm.asset.id_asset,
                                                              index_model
                                                          ).color
                                                        : '#9399a3',"value":_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index_model, 'percent'),"height":"6","rounded":""}})]:[_c('v-progress-linear',{attrs:{"color":"#9399a3","height":"6","rounded":""}})]],2)]})],2),_c('div',{staticClass:"d-flex flex-column gap-2 overflow-hidden"},[_vm._l((_vm.asset.devices),function(device){return [_c('div',{key:device.id_device},[_c('div',{staticClass:"display-flex-align-center gap-2 mb-2"},[(!_vm.isErrorOnDeviceReferentielImage)?_c('img',{attrs:{"src":device.path_image_device_referentiel,"height":"40"},on:{"error":function($event){_vm.isErrorOnDeviceReferentielImage = true}}}):_vm._e(),(_vm.isErrorOnDeviceReferentielImage)?_c('v-icon',[_vm._v(" mdi-access-point-network ")]):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipsis"},'div',attrs,false),on),[_vm._v(" "+_vm._s(device.device_number)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(device.device_number)+" ")])],1),_c('chips-last-statement',{attrs:{"date":device.date_last_emission,"colorless":""}})],1)]})],2)],2)]:[_c('div',{staticClass:"d-flex align-center justify-center flex-column gap-1 h-70"},[_c('v-icon',{staticClass:"font-color-light",attrs:{"x-large":""}},[_vm._v("mdi-cancel")]),_c('p',{staticClass:"font-color-light text-uppercase mb-1"},[_vm._v("Abonnement expiré")])],1)]],2)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }