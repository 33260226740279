<template>
    <div id="asset-generic-card-minimalist-component" class="h-100">
        <template v-if="is_mobile">
            <v-card
                :class="`${is_selected ? 'minimalist-asset-card-selected' : 'minimalist-asset-card'} h-100 text-center pointer`"
                elevation="0"
            >
                <v-card-text class="pa-1">
                    <div class="text-center font-12 text-overflow-ellipsis">
                        {{ asset.name }}
                    </div>

                    <div class="font-weight-bold font-10 font-color-medium mb-1">
                        {{ asset.code_asset }}
                    </div>

                    <template v-for="(n, index_model) in total_models">
                        <div :key="n" class="mb-2">
                            <template v-if="configuration[index_model]['is_required']">
                                <v-progress-linear
                                    :color="
                                        is_selected
                                            ? $store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                                                  asset_module.id_module,
                                                  asset.id_asset,
                                                  index_model
                                              ).color
                                            : '#9399a3'
                                    "
                                    :value="getConfigurationDatatableAssetVisionData(asset, index_model, 'percent')"
                                    height="4"
                                    rounded
                                />
                            </template>

                            <template v-else>
                                <v-progress-linear color="#9399a3" height="4" rounded />
                            </template>
                        </div>
                    </template>

                    <div class="font-10 font-color-light line-height-12">
                        <v-icon x-small> mdi-map-marker-outline</v-icon>
                        {{ asset.domicile.address }}<br />
                        {{ asset.domicile.postal_code }} {{ asset.domicile.city }}
                    </div>
                </v-card-text>
            </v-card>
        </template>

        <template v-else>
            <div class="position-relative">
                <v-card
                    :class="`${is_selected ? 'minimalist-asset-card-selected' : 'minimalist-asset-card'} desktop-asset-card h-100 pointer`"
                    data-cy="minimalist-asset-card"
                    elevation="0"
                >
                    <v-card-text class="h-100 position-relative">
                        <div class="font-weight-bold font-18 text-overflow-ellipsis font-color-default">
                            {{ asset.name }}
                        </div>

                        <div class="font-weight-bold font-12 font-color-medium">
                            {{ asset.code_asset }}
                        </div>

                        <v-tooltip open-delay="200" top>
                            {{ getFormattedAddress(asset.domicile) }}

                            <template v-slot:activator="{ on, attrs }">
                                <div class="font-12 ml-n1 mb-2 d-flex line-height-16 min-height-32" v-bind="attrs" v-on="on">
                                    <v-icon class="font-color-medium align-stretch" dense> mdi-map-marker-outline </v-icon>
                                    <span class="font-color-medium text-overflow-ellipsis">
                                        {{ getFormattedAddress(asset.domicile) }}
                                    </span>
                                </div>
                            </template>
                        </v-tooltip>

                        <template v-if="asset.is_subscription_device_active">
                            <div
                                :class="`
                                    ${total_models === 1 ? 'd-flex justify-space-between align-center gap-2 ' : ''}  overflow-hidden
                                `"
                            >
                                <template v-if="total_models === 1">
                                    <template v-if="configuration[0]['is_required']">
                                        <display-asset-content-level-component
                                            :asset="asset"
                                            :asset_module="asset_module"
                                            :colorless="!is_selected"
                                            :index_model="0"
                                            :scale="0.4"
                                        />
                                    </template>

                                    <template v-else>
                                        <div class="d-flex grow justify-center">
                                            <v-icon class="font-color-light"> mdi-cancel</v-icon>
                                        </div>
                                    </template>
                                </template>

                                <div v-else class="d-flex flex-column gap-2 mb-4">
                                    <template v-for="(n, index_model) in total_models">
                                        <div :key="n" class="flex-column gap-2 position-relative">
                                            <template v-if="configuration[index_model]['is_required']">
                                                <v-progress-linear
                                                    :color="
                                                        is_selected
                                                            ? $store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                                                                  asset_module.id_module,
                                                                  asset.id_asset,
                                                                  index_model
                                                              ).color
                                                            : '#9399a3'
                                                    "
                                                    :value="getConfigurationDatatableAssetVisionData(asset, index_model, 'percent')"
                                                    height="6"
                                                    rounded
                                                />
                                            </template>
                                            <template v-else>
                                                <v-progress-linear color="#9399a3" height="6" rounded />
                                            </template>
                                        </div>
                                    </template>
                                </div>

                                <div class="d-flex flex-column gap-2 overflow-hidden">
                                    <template v-for="device in asset.devices">
                                        <div :key="device.id_device">
                                            <div class="display-flex-align-center gap-2 mb-2">
                                                <img
                                                    v-if="!isErrorOnDeviceReferentielImage"
                                                    :src="device.path_image_device_referentiel"
                                                    height="40"
                                                    v-on:error="isErrorOnDeviceReferentielImage = true"
                                                />
                                                <v-icon v-if="isErrorOnDeviceReferentielImage"> mdi-access-point-network </v-icon>

                                                <v-tooltip open-delay="200" top>
                                                    {{ device.device_number }}

                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div class="text-overflow-ellipsis" v-bind="attrs" v-on="on">
                                                            {{ device.device_number }}
                                                        </div>
                                                    </template>
                                                </v-tooltip>
                                            </div>

                                            <chips-last-statement :date="device.date_last_emission" colorless />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="d-flex align-center justify-center flex-column gap-1 h-70">
                                <v-icon class="font-color-light" x-large>mdi-cancel</v-icon>
                                <p class="font-color-light text-uppercase mb-1">Abonnement expiré</p>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import DisplayAssetContentLevelComponent from '@/components/Equipment/Generic/DisplayAssetContentLevelComponent.vue'
import useAsset from '@/mixin/useAsset'

export default {
    name: 'AssetGenericCardMinimalistComponent',
    components: { DisplayAssetContentLevelComponent, ChipsLastStatement },
    mixins: [useAsset],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
        is_selected: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isErrorOnDeviceReferentielImage: false,
        }
    },
    computed: {
        total_models() {
            return this.asset_module.total_models || 0
        },
        configuration() {
            return JSON.parse(this.asset.configuration)
        },
    },
}
</script>
