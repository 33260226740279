<template>
    <div>
        <v-dialog v-model="id_dialog_edit_user_alert_contact_preferences_open" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="text-uppercase font-weight-bold mb-1 line-height-24">
                        {{ $t('profile_page.contact_preferences') }}
                    </p>
                </v-card-title>

                <v-card-text class="mt-4">
                    <!-- Rebuild v-for to fix bug -->
                    <v-form>
                        <v-row class="ma-0" justify="space-around">
                            <!-- ALERT_BADGE
                            <div>
                                <p class="font-12 ml-1 mb-0">
                                    {{ $t('dialog_update_alert_component.badge') }}
                                </p>
                                <v-switch v-model="user.alert_badge" class="mt-0" hide-details inset />
                            </div>-->

                            <div>
                                <p class="font-12 ml-1 mb-0">
                                    {{ $t('dialog_update_alert_component.notifications') }}
                                </p>
                                <v-switch v-model="user.alert_push" class="mt-0" hide-details inset />
                            </div>

                            <div>
                                <p class="font-12 ml-2 mb-0">
                                    {{ $t('dialog_update_alert_component.sms') }}
                                </p>
                                <v-switch v-model="user.alert_sms" class="mt-0" hide-details inset />
                            </div>

                            <div>
                                <p class="font-12 ml-1 mb-0">
                                    {{ $t('dialog_update_alert_component.email') }}
                                </p>
                                <v-switch v-model="user.alert_mail" class="mt-0" hide-details inset />
                            </div>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="id_dialog_edit_user_alert_contact_preferences_open = false">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn
                        :loading="editing_user_alert_contact_preferences"
                        class="font-weight-bold btn-secondary-loader"
                        color="secondary"
                        text
                        @click="updateUserProfile()"
                    >
                        <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import UserRepository from '@/repositories/UserRepository'

export default {
    name: 'edit-user-alert-contact-preferences-dialog-component',
    data() {
        return {
            id_dialog_edit_user_alert_contact_preferences_open: false,
            editing_user_alert_contact_preferences: false,
            user: { alert_badge: false, alert_mail: false, alert_push: false, alert_sms: false },
        }
    },
    methods: {
        openEditUserAlertContactPreferencesDialog(user) {
            this.user = { ...user }
            this.id_dialog_edit_user_alert_contact_preferences_open = true
        },

        updateUserProfile() {
            this.editing_user_alert_contact_preferences = true
            UserRepository.updateUserProfile(this.user)
                .then(() => {
                    this.showSnackbar('success', this.$t('profile_page.success_profile_updated'))
                    this.id_dialog_edit_user_alert_contact_preferences_open = false
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.editing_user_alert_contact_preferences = false
                })
        },
    },
}
</script>
