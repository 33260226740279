import DeviceTraitedDataRepository from '@/repositories/DeviceTraitedDataRepository'

const state = () => ({
    show_global_loader: true,
    id_professional: null,
    application_version: '',
    dtd_triggers: [],
})

const mutations = {
    setGlobalLoader(state, globalLoader: boolean): void {
        state.show_global_loader = globalLoader
    },

    setIdProfessional(state, idProfessional: number): void {
        state.id_professional = idProfessional
    },

    setApplicationVersion(state, applicationVersion: string): void {
        state.application_version = applicationVersion
    },
    setDtdTriggers(state, dtd_triggers: any[]): void {
        state.dtd_triggers = dtd_triggers
    },
}

const actions = {
    async setGlobalLoader({ commit }, globalLoader: boolean): Promise<void> {
        await commit('setGlobalLoader', globalLoader)
    },

    async setIdProfessional({ commit }, idProfessional: number): Promise<void> {
        await commit('setIdProfessional', idProfessional)
    },

    async setApplicationVersion({ commit }, applicationVersion: string): Promise<void> {
        await commit('setApplicationVersion', applicationVersion)
    },

    async getDtdTriggers({ commit }): Promise<void> {
        await DeviceTraitedDataRepository.getDtdTriggers().then((success) => {
            commit('setDtdTriggers', success.data.data)
        })
    },
}

const getters = {
    show_global_loader: (state) => state.show_global_loader,
    id_professional: (state) => state.id_professional,
    application_version: (state) => state.application_version,
    dtd_triggers: (state) => state.dtd_triggers,
    dtd_triggers_array: (state) => {
        return Object.keys(state.dtd_triggers).map((key) => {
            return { value: key, label: state.dtd_triggers[key] }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
